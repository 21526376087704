.container {
  margin: 20px 0;
  width: 100%;
  .cropper_container {
    margin: 20px 0;
  }

  .btn_container {
    margin: 20px 0;
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
  }

  label {
    display: block;
    font-size: 14px;
    font-weight: 600;
    margin-bottom: 10px;
  }

  .preview_container {
    display: flex;
    justify-content: center;

    .preview {
      display: block;
      margin: 20px 0;
      max-width: 100%;
      max-height: 300px;
      border: 1px solid #eee;
    }
  }

  .errors {
    color: #ef4e4e;
  }
}
