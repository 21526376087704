$blue: rgba(33, 134, 235, 1);
$red: rgba(255, 67, 104, 1);
$border-color: #efefef;
$orange: #f8981c;
$nav-background: rgba(247, 247, 250, 0.6);

.container {
  .questions {
    display: flex;
    .left {
      width: 50%;
      padding-right: 50px;
    }
  
    .right {
      width: 50%;
    }
  }
}
