.container {
  position: relative;
  height: 0;
  padding-top: 76%;
  border: 1px solid #eee;
  // background-color: #efefef;
  // background-position: center;
  // background-repeat: no-repeat;
  // background-size: cover;

  img {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    max-width: 100%;
    max-height: 100%;
  }

  &:hover {
    // opacity: 0.8;
    cursor: pointer;

    .overlay {
      display: block;
    }
  }

  .overlay {
    position: absolute;
    display: none;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 100%;
    height: 100%;
    background: rgba(50, 50, 50, 0.8);
    z-index: 99;

    span {
      position: absolute;
      font-size: 14px;
      width: 100%;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      display: block;
      padding: 10px;
      color: #fff;
      overflow: hidden;
    }
  }
}
