$blue: rgba(33, 134, 235, 1);
$red: rgba(255, 67, 104, 1);
$border-color: #efefef;
$orange: #f8981c;
$nav-background: rgba(247, 247, 250, 0.6);

.wrapper {
  padding: 0 20px;

  .controls_container {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    .input_sm {
      margin-right: 10px;
    }
  }
}

.container {
  width: 100%;
  display: flex;
  margin-top: 20px;
  height: 70vh;
  overflow-y: scroll;
  display: grid;
  grid-template-columns: repeat(8, minmax(100px, 1fr));
  grid-auto-rows: max-content;
  gap: 10px;

  .controls_container {
    padding: 0 10px;
  }
}

@media (max-height: 900px) {
  .container {
    height: 70vh;
  }
}

@media (max-height: 850px) {
  .container {
    height: 65vh;
  }
}

// Scaling fix
// @media (max-width: 1536px) and (max-height: 864px) and (-webkit-min-device-pixel-ratio: 1.25) {
//   .container {
//     height: 60vh;
//   }
// }
