$blue: rgba(33, 134, 235, 1);
$red: rgba(255, 67, 104, 1);
$border-color: #efefef;
$orange: #f8981c;
$nav-background: rgba(247, 247, 250, 0.6);

.header {
  padding: 12px 20px;
  border-bottom: 2px solid $border-color;
  text-transform: uppercase;
  color: #000;
  font-weight: 600;
  font-size: 12px;
  background: $nav-background;
  list-style-type: none;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
