$blue: rgba(33, 134, 235, 1);
$red: rgba(255, 67, 104, 1);
$border-color: #efefef;
$orange: #f8981c;
$nav-background: rgba(247, 247, 250, 0.6);

.list_container {
  max-width: 1000px;
  // min-height: 520px;
  border: 1px solid $border-color;
  border-radius: 5px;
  ul {
    list-style: none;
    font-size: 14px;
    margin: 0;
    padding: 0;

    li {
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding: 12px 20px;
      border-bottom: 1px solid $border-color;

      .button-container {
        display: flex;
        text-align: center;
        padding: 0 !important;

        .button:last-child {
          margin-right: 0;
        }
      }
      .list-inner {
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: space-between;

        .name {
          width: 30%;
        }
        .email {
          width: 40%;
        }
        .role {
          width: 10%;
        }
      }
    }

    li:hover {
      background: rgba(247, 247, 250, 0.6);
    }
    li:nth-last-child(1) {
      border: none;
    }
    .subcats-container {
      padding: 10px 0;
      li {
        padding: 5px 0 5px 5px;
        border-bottom: none;
      }
    }
  }
}
