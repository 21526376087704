.poll-settings-container {
  .poll-settings-checkbox {
    display: flex;

    input {
      display: block;
      margin-left: 5px;
      cursor: pointer;
      width: 17px;
      height: 17px;
    }
  }
}
