.container {
  min-height: 550px;
  overflow-y: auto;
  width: 1200px;
  padding: 0 20px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  .right {
    width: 50%;
    display: flex;
    justify-content: center;
  }

  .preview {
    display: block;
    margin: 20px 0;
    max-width: 100%;
    max-height: 300px;
    border: 1px solid #eee;
    text-align: center;
  }
}
