$blue: rgba(33, 134, 235, 1);
$red: rgba(255, 67, 104, 1);
$border-color: #efefef;
$orange: #f8981c;
$nav-background: rgba(247, 247, 250, 0.6);

.container {
  list-style-type: none;
  padding: 0;
  height: 60vh;
  overflow-y: auto;

  .list_item {
    padding: 12px 20px;
    border: 1px solid $border-color;
    cursor: pointer;

    &:hover {
      background: rgba(247, 247, 250, 0.6);
    }

    div {
      margin: 5px 0;
    }

    .type {
      font-size: 13px;
    }
  }
}
