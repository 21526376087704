$blue: rgba(33, 134, 235, 1);

.container {
  position: fixed;
  top: 0;
  right: 50px;
  list-style-type: none;
  max-width: 200px;
  padding: 5px;
  z-index: 999;

  li {
    display: inline-block;
    padding: 5px;
    text-transform: uppercase;
    cursor: pointer;
    font-weight: 600;
    border-radius: 7px;
    border: 1px solid transparent;
  }

  li:hover {
    color: $blue;
  }
}
