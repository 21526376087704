$blue: rgba(33, 134, 235, 1);
$red: rgba(255, 67, 104, 1);
$border-color: #efefef;
$orange: #f8981c;
$nav-background: rgba(247, 247, 250, 0.6);

.container {
  width: 100%;
  margin-top: 20px;

  ul {
    list-style-type: none;
    border: 1px solid $border-color;
    max-width: 1000px;
    padding: 0;

    .list_item {
      padding: 12px;
      border-bottom: 1px solid $border-color;

      display: flex;
      justify-content: flex-start;
      align-items: center;

      div:nth-child(1) {
        width: 20%;
      }
      div:nth-child(2) {
        width: 20%;
      }
      div:nth-child(3) {
        width: 30%;
      }
      div:nth-child(4) {
        width: 15%;
      }
      div:nth-child(5) {
        width: 15%;
      }
    }

    li:hover {
      background: rgba(247, 247, 250, 0.6);
    }
    li:nth-last-child(1) {
      border: none;
    }
  }

  .table_header {
    max-width: 1000px;
    padding: 0 12px;
    font-size: 13px;

    display: flex;
    justify-content: flex-start;
    align-items: center;

    div:nth-child(1) {
      width: 20%;
    }
    div:nth-child(2) {
      width: 20%;
    }
    div:nth-child(3) {
      width: 30%;
    }
    div:nth-child(4) {
      width: 15%;
    }
    div:nth-child(5) {
      width: 15%;
    }
  }
}
