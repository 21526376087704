$blue: rgba(33, 134, 235, 1);
$red: rgba(255, 67, 104, 1);
$border-color: #efefef;
$orange: #f8981c;
$nav-background: rgba(247, 247, 250, 0.6);

.container {
  ul {
    list-style-type: none;
    padding: 0;

    li {
      display: flex;
      align-items: center;

      div {
        margin-right: 10px;
      }

      span {
        display: block;
        padding-top: 17px;
        cursor: pointer;

        &:hover {
          font-style: italic;
          color: $red;
        }
      }
    }
    li > div {
      margin: 10px 0;
    }
  }

  .optionHeader {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-right: 20px;

    span {
      display: block;
      color: $blue;
      cursor: pointer;
      width: fit-content;

      &:hover {
        font-style: italic;
      }
    }
  }

  .error {
    color: $red;
  }
}
