.dialog_inner {
  min-width: 400px;
  padding: 0 20px;
}

.dialog_header {
  display: flex;
  justify-content: space-between;

  h3,
  i {
    display: block;
  }
}
