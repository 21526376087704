.container {
  padding: 10px;
  margin: 20px 0;
  width: fit-content;
  min-height: 150px;
  min-width: 300px;

  .image_preview {
    display: block;
    max-width: 100%;
    height: 250px;
  }

  .invalid_feedback {
    display: block;
    margin-top: 5px;
    color: #ef4e4e;
    font-weight: 600;
    i {
      margin: 0 5px 0 0;
    }
  }
}
