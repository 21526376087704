.container {
  width: 1200px;

  .dialog_header {
    display: flex;
    justify-content: space-between;

    h3,
    i {
      display: block;
    }
  }
}

.form {
  text-align: left;
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  .col {
    width: 100%;
    padding: 20px;
  }
}

.btn_container {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

@media (max-width: 1280px) {
  .container {
    width: 1150px;
  }
}
