.container {
  margin: 20px 0;
  .cropper_container {
    margin: 20px 0;
  }

  .btn_container {
    margin: 20px 0;
    display: flex;
    align-items: center;
    width: 300px;
  }

  label {
    display: block;
    font-size: 14px;
    font-weight: 600;
    margin-bottom: 10px;
  }

  .preview {
    display: block;
    margin: 20px 0;
    width: 300px;
  }

  .errors {
    color: #ef4e4e;
  }
}
