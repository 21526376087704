.container {
  min-height: 480px;
}

.list_controls {
  display: flex;
  justify-content: flex-start;
  align-items: center;
}

.header_container {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
