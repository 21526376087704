.container {
  width: 50%;
  padding: 30px;
  border-left: 1px solid #e3e3e3;

  .header {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  .btn_container {
    display: flex;
    justify-content: space-between;
  }
}
