$blue: rgba(33, 134, 235, 1);
$red: rgba(255, 67, 104, 1);
$border-color: #efefef;
$orange: #f8981c;
$nav-background: rgba(247, 247, 250, 0.6);

.container {
  width: 50%;
  padding: 30px;

  ul {
    list-style-type: none;
    border: 1px solid $border-color;
    width: 100%;
    padding: 0;
    li {
      padding: 12px 20px;
      border-bottom: 1px solid $border-color;

      &:hover {
        background: rgba(247, 247, 250, 0.6);
        cursor: pointer;
      }
    }
  }
}
