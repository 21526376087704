$blue: rgba(33, 134, 235, 1);
$red: rgba(255, 67, 104, 1);
$border-color: #efefef;
$orange: #f8981c;
$nav-background: rgba(247, 247, 250, 0.6);

.error {
  color: $red;
}

.isRequired {
  display: flex;

  input {
    display: block;
    margin-left: 5px;
    cursor: pointer;
    width: 17px;
    height: 17px;
  }
}
