.dialog_header {
  display: flex;
  justify-content: space-between;

  h3,
  i {
    display: block;
  }
}
.form {
  text-align: left;
  min-width: 420px;
}

.btn_container {
  margin-top: 20px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
