$blue: rgba(33, 134, 235, 1);
$red: rgba(255, 67, 104, 1);
$border-color: #efefef;
$orange: #f8981c;
$nav-background: rgba(247, 247, 250, 0.6);

.businessContainer {
  min-height: 480px;
}

.tab {
  text-transform: uppercase;
  margin-right: 10px;
  padding-bottom: 5px;
  border-bottom: 2px solid #ccc;
  cursor: pointer;
}

@supports (-moz-appearance: none) {
  .businessContainer {
    margin-bottom: 20px;
  }
}
